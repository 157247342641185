import { useEffect } from "react";
import { useParams } from 'react-router-dom'
import { useSignal } from '@preact/signals-react';

import NotFound from '@pages/NotFound';
import { Separator } from '@components/ui/separator';

import ProductTabs from '@components/product/ProductTabs';
import ProductGrid from '@components/product/ProductGrid';
import RelatedProducts from '@components/product/RelatedProducts';

import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useContext } from "react";
import { LoadingPage } from "@app";

const apiURL = process.env.REACT_APP_API_URL;

const ContactUs = () => {
    const contacts = [
        { icon: faPhone, href: "tel:+393927533470" },
        { icon: faEnvelope, href: "mailto:info@nicolcasa.it" },
        { icon: faFacebook, href: "https://www.facebook.com/people/Nicol-Casa-Gran-Bazar/100063640967730/" },
        { icon: faLocationDot, href: "https://www.google.it/maps/place/Corso+Roma,+228,+74016+Massafra+TA/@40.5846838,17.1203553,16z/data=!3m1!4b1!4m6!3m5!1s0x13470637b2020ed3:0x5db684adfdb84038!8m2!3d40.5846838!4d17.1229302!16s%2Fg%2F11c29khwg9?entry=ttu" },
    ]

    return (
        <div className="my-20 md:my-32">
            <div className="flex flex-col justify-center items-center gap-2">
                <h6 className="first-letter:capitalize font-semibold text-xl">non perdere tempo,</h6>
                <h5 className="font-semibold uppercase text-5xl md:text-8xl">contattaci</h5>
                <div className="flex justify-center items-center gap-5 md:gap-10 lg:gap-20 my-10">
                    {contacts.map((contact, index) => (
                        <a
                            key={index}
                            href={contact.href}
                            target="_blank"
                            rel="noreferrer"
                            className={`
                            text-4xl md:text-5xl lg:text-7xl bg-white text-[#FCF8F3] rounded-full flex justify-center
                            items-center w-[4rem] md:w-[7rem] lg:w-[9rem] aspect-1 p-0 md:p-8 transition-all group
                            hover:drop-shadow-none duration-200 hover:shadow-[inset_0px_5px_4px_#0000002f] drop-shadow-[0px_5px_4px_#0000002f]
                        `}
                        >
                            <FontAwesomeIcon
                                icon={contact.icon}
                                className={`
                                transition-all duration-200 drop-shadow-[0px_5px_4px_#0000002f]
                                group-hover:drop-shadow-[0px_-5px_4px_#0000002f]
                            `}
                            />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

const Product = () => {
    const { isLoading, setIsLoading } = useContext(LoadingPage);
    const { slug, vid } = useParams();
    const product = useSignal(null);

    const fetchProduct = async () => {
        try {
            const response = await fetch(`${apiURL}/products/${slug}/${!vid ? "" : vid}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.ok) {
                const p = await response.json();
                console.log(p);
                return p.product;
            }

            const errorResponse = await response.json();
            throw new Error(`${errorResponse.message}`);
        } catch (error) {
            console.error(error.message);
            return false;
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    useEffect(() => {
        (async () => {
            product.value = await fetchProduct();
            if (product.value) {
                setIsLoading(false);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug, vid])

    if (isLoading) {
        return (<></>);
    }

    if (!slug || product.value === false)
        return (<NotFound />);

    return (
        <div className="h-fit px-[5%] md:px-16 lg:px-20 sm:px-8 xl:px20 2xl:px-32 md:gap-5 gap-3 flex flex-col justify-center items-center">
            <ProductGrid product={product} />
            <ProductTabs product={product} />
            {product?.value?.related_products.length > 0 && (
                <>
                    <Separator className="w-full my-4 mt-10 h-[4px] bg-[#EEE5DE] rounded-full" />
                    <RelatedProducts product={product} />
                </>
            )}
            <Separator className="w-full my-4 mt-10 h-[4px] bg-[#EEE5DE] rounded-full" />
            <ContactUs />
        </div>
    );
}

export default Product;