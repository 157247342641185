import { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useSignal } from "@preact/signals-react";

/*
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { Button } from '@components/ui/button'

import getImages from '@lib/blob';

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@components/ui/carousel"
*/

const Textures = ({ attributes }) => {
	return (
		<div className="absolute bottom-0 left-0 w-full flex justify-start gap-1 p-2">
			{attributes
				.find(({ name }) => name === "colore")
				?.options.map((opt, key) => {
					return (
						<div
							key={key}
							className={`w-6 aspect-1 border-zinc-400 border-2 rounded-full`}
							style={{
								background: opt.primary_color,
							}}
						/>
					);
				})}
		</div>
	);
};

const ProductSlider = ({ index, product }) => {
	const navigate = useNavigate();
	const imgIndex = useSignal(0);
	const containerRef = useRef(null);
	const isInView = useInView(containerRef, { once: true });

	useEffect(() => {
		console.log(product);
	}, []);

	return (
		<div
			ref={containerRef}
			className={`
            ${index % 2 === 0 ? "delay-100" : "delay-300"}
            w-fit h-fit transition-all duration-1000
            ${isInView ? "opacity-100" : "opacity-0 translate-y-[10rem]"}
            w-full h-full cursor-pointer
        `}
		>
			<div
				onClick={() => {
					navigate(`/prodotti/${product.slug}`);
				}}
				className={`
                    flex flex-col justify-start items-center bg-transparent duration-300
                    text-center transition-all w-full h-full group gap-3
                `}
			>
				<div className="relative flex flex-col justify-start items-center md:gap-4">
					<div className="flex h-fit w-full justify-center items-center overflow-clip rounded-sm shadow-lg shadow-black/5">
						<motion.img
							src={product.images[imgIndex].src}
							className={`
                            min-w-32 w-full aspect-[1/1] object-cover transition-transform 
                            duration-300 group-hover:scale-110
                        `}
						/>
					</div>
					{/* 					<Textures attributes={product.attributes} /> */}
				</div>
				<div className="w-full flex flex-col justify-center items-start min-h-[1.3rem] md:min-h-[3rem] transition-all duration-300 leading-4 text-start">
					<h3 className="w-full text-sm md:text-lg font-bold">
						{product.name}
					</h3>
					<h3 className="w-full text-sm md:text-lg font-medium text-green-600">
						{product.price}€
					</h3>
				</div>
				{/*             <div className="w-full flex flex-col justify-center items-center text-white bg-[#635B55] min-h-[1.3rem] md:min-h-[3rem] scale-125 rounded-[2px] md:rounded-sm shadow-md">
                <h3 className="top-0 left-0 text-[11px] md:text-2xl font-bold">{product.price}€</h3>
            </div> */}
			</div>
		</div>
	);
};

export default ProductSlider;
