import { useContext } from "react";
import { LoadingPage } from "@app";

import { useEffect, useRef, useState } from "react";
import {
	useLocation,
	useSearchParams,
	createSearchParams,
} from "react-router-dom";
import { useSignal, batch } from "@preact/signals-react";
import { motion, useInView } from "framer-motion";

import BackgroundImage from "@components/ui/BackgroundImage";
import RelatedCategories from "@components/categories/RelatedCategories";

import ProductSlider from "@components/product/ProductSlider";
import Filters from "@components/product/Filters";

import ReactHtmlParser from "html-react-parser";
import { createPath } from "@lib/createPath";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompactDisc } from "@fortawesome/free-solid-svg-icons";

const apiURL = process.env.REACT_APP_API_URL;

const fetchCategories = async () => {
	try {
		const apiURL = process.env.REACT_APP_API_URL;
		const response = await fetch(`${apiURL}/categories`, {
			method: "GET",
			mode: "cors",
		});

		if (response.ok) {
			const json = await response.json();
			return [...json.categories];
		}

		const errorResponse = await response.json();
		throw new Error(`${errorResponse.message}`);
	} catch (error) {
		console.error(error.message);
		return false;
	}
};

const fetchProducts = async (path) => {
	try {
		const response = await fetch(path, {
			method: "GET",
			mode: "cors",
		});

		if (response.ok) {
			return await response.json();
		}

		const errorResponse = await response.json();
		throw new Error(`${errorResponse.message}`);
	} catch (error) {
		console.error(error.message);
		return false;
	}
};

const EndOfList = ({ onView }) => {
	const ref = useRef(null);
	const isInView = useInView(ref);

	useEffect(() => {
		if (isInView === true && typeof onView === "function") {
			onView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInView]);

	return <div ref={ref} />;
};

const ProductList = () => {
	const { isLoading, setIsLoading } = useContext(LoadingPage);

	const [searchParams] = useSearchParams();
	const location = useLocation();
	const searchValue = new URLSearchParams(useLocation().search).get("search");
	const categoryValue = new URLSearchParams(useLocation().search).get(
		"category"
	);

	const [page, setPage] = useState(1);

	const products = useSignal([]);
	const isDownloadingProducts = useSignal(true);
	const categories = useSignal(null);
	const selectedCategoryImage = useSignal("");
	const isEndOfProducts = useSignal(false);

	const productListRef = useRef(null);
	const ref = useRef(null);

	const getSelectedCategory = () => {
		const selectedCategory = categoryValue.toLowerCase();
		return categories.value
			.find(({ name }) => name === "categoria")
			.childs.find((category) => category.name === selectedCategory);
		//return images.categories.find((category) => category.name.toLowerCase() === selectedCategory);
	};

	const displayDownloading = () => {
		if (isDownloadingProducts.value) {
			return (
				<div className="col-span-2 md:col-span-4 py-40 w-full flex justify-center items-center">
					<FontAwesomeIcon
						className="animate-spin-slow text-8xl"
						icon={faCompactDisc}
					/>
				</div>
			);
		} else
			return (
				<div className="col-span-2 md:col-span-4 py-40 w-full flex justify-center items-center">
					<h1 className="font-medium md:text-4xl text-2xl text-center px-[6%]">
						La ricerca non ha prodotto alcun risultato
					</h1>
				</div>
			);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		(async () => {
			const time = 10 * 1000;
			const timeOutID = setTimeout(() => {
				isDownloadingProducts.value = false;
			}, time);
			if (!categories.peek()) categories.value = await fetchCategories();

			ref?.current?.scrollIntoView({ behavior: "smooth" });
			if (categoryValue) {
				const selectedCategory = getSelectedCategory();
				if (selectedCategory)
					selectedCategoryImage.value = selectedCategory.image;
			}
			const path = createPath({
				pathName: [apiURL, "products"],
				search: createSearchParams({ search: searchValue }),
				category: createSearchParams({ category: categoryValue }),
				limit: createSearchParams({ limit: 20 }),
				page: createSearchParams({ page: 1 }),
				...Array.from(searchParams.entries()).reduce(
					(acc, [key, value]) => {
						acc[key] = createSearchParams({ [key]: value });
						return acc;
					},
					{}
				),
			});
			const newProducts = await fetchProducts(path);
			products.value = newProducts.products;
			if (products.value.length < 1) {
				isDownloadingProducts.value = false;
				clearTimeout(timeOutID);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	useEffect(() => {
		isEndOfProducts.value = false;
		setPage(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		batch(async () => {
			if (page > 1) {
				const path = createPath({
					pathName: [apiURL, "products"],
					search: createSearchParams({ search: searchValue }),
					category: createSearchParams({
						category: categoryValue ? categoryValue : "",
					}),
					limit: createSearchParams({ limit: 20 }),
					page: createSearchParams({ page: page }),
					...Array.from(searchParams.entries()).reduce(
						(acc, [key, value]) => {
							acc[key] = createSearchParams({ [key]: value });
							return acc;
						},
						{}
					),
				});

				const newProducts = await fetchProducts(path);
				if (newProducts.end) isEndOfProducts.value = true;
				if (newProducts.products.length === 0) return;

				products.value = [...products.peek(), ...newProducts.products];
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products.value]);

	if (isLoading) return <></>;

	return (
		<>
			{categoryValue && selectedCategoryImage.value && (
				<BackgroundImage
					name={categoryValue?.toLowerCase()}
					src={selectedCategoryImage.value}
					onClick={() => {
						ref?.current?.scrollIntoView({ behavior: "smooth" });
					}}
				/>
			)}

			<section
				ref={ref}
				className="relative min-h-0 h-fit my-20 xl:flex mb-20"
			>
				<motion.div
					initial={{ translateX: "-30%" }}
					animate={{
						translateX: 0,
						transition: {
							duration: 0.8,
							delay: 0.2,
							type: "spring",
						},
					}}
					className="sticky h-fit max-h-[80vh] overflow-y-auto top-0 xl:w-[20rem] xl:bg-transparent bg-[#FCF8F3] w-full px-5 z-[80]"
				>
					<Filters />
				</motion.div>
				<div
					ref={productListRef}
					className="w-full min-h-0 min-w-0 grid grid-cols-2 md:grid-cols-4 py-5 gap-4 px-4 pb-40"
				>
					{products.value.length >= 1
						? products.peek().map((product, index) => (
								<ProductSlider
									key={product.id}
									index={index}
									product={{
										...product,
										short_description: ReactHtmlParser(
											product.short_description
										),
									}}
								/>
						  ))
						: displayDownloading()}
					<div className="col-span-2 md:col-span-4 bg-black">
						<EndOfList
							onView={() => {
								if (isEndOfProducts.value) return;
								setPage(page + 1);
							}}
						/>
					</div>
				</div>
			</section>

			{categoryValue && selectedCategoryImage.value && (
				<RelatedCategories
					images={categories.value
						.find(({ name }) => name === "categoria")
						.childs.map(({ name, image }) => ({
							name,
							src: image,
						}))}
					selectedCategory={categoryValue.toLowerCase()}
				/>
			)}
		</>
	);
};

export default ProductList;
