import { useNavigate, createSearchParams } from "react-router-dom";
import { motion } from 'framer-motion';

const categories = {
    pathPrefix: "/img/categories/icons/",
    icons: [
        { name: "Divani/Poltrone", src: "c_Poltrona.png" },
        { name: "Quadri/Specchi", src: "c_Specchio.png" },
        { name: "Tavoli/Sedie", src: "c_Tavolo.png" },
    ]
}

export default function HomeCategories() {
    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate({
            pathname: '/' + path.pathname.join('/'),
            search: createSearchParams(path.search).toString()
        })
    }

    return (
        <section className="flex flex-col justify-center items-center md:mb-20 gap-10 overflow-x-clip">
            <div className="w-full px-40 flex flex-wrap justify-center items-center gap-8 py-5">
                {
                    categories.icons.map((category, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, translateY: '50%' }}
                            whileInView={{ opacity: 1, translateY: 0, transition: { delay: .3 } }}
                            viewport={{ once: true }}
                            className={`
                                relative min-h-40 shadow-md bg-[#635B55] group rounded-xl flex w-full max-w-[25rem]
                                min-w-[20rem] flex-col justify-start items-start gap-2 py-[3rem] font-semibold px-10 
                            `}>

                            <h6 className="px-4 transition-all duration-500 group-hover:opacity-0 first-letter:capitalize text-white text-xl w-[40%] text-center z-30">{category.name}</h6>
                            <div className="absolute w-full h-full left-0 top-0 flex justify-start items-center px-10 z-30">
                                <button
                                    onClick={() => navigateTo({
                                        pathname: ['prodotti'],
                                        search: {
                                            category: category.name.toLowerCase()
                                        }
                                    })}
                                    className="scale-[0.4] duration-500 group-hover:scale-100 group-hover:mt-0 mt-10 px-4 py-[1.5rem] text-3xl md:text-4xl w-full rounded-full bg-[#EEE5DE] first-letter:capitalize transition-all origin-left flex flex-nowrap justify-center">
                                    scopri di più
                                </button>
                            </div>
                            <div className="absolute w-full h-full left-0 top-0 flex justify-end items-center px-10 overflow-hidden z-10 group-hover:opacity-50 transition-all translate-x-10 duration-500">
                                <img
                                    loading="lazy"
                                    decoding="async"
                                    fetchpriority="medium"
                                    src={categories.pathPrefix + category.src} alt="" className="h-40 object-cover scale-[1.8] translate-y-10"
                                />
                            </div>
                        </motion.div>
                    ))
                }

            </div>
            <motion.button
                initial={{ opacity: 0, translateY: '50%' }}
                whileInView={{ opacity: 1, translateY: 0, transition: { delay: .2 } }}
                viewport={{ once: true }}
                onClick={() => navigateTo({
                    pathname: ['prodotti'],
                    search: {}
                })}
                className={`
                    px-10 py-4 w-fit rounded-full bg-[#EEE5DE] first-letter:capitalize
                    font-semibold text-xl uppercase flex transition-shadow
                    hover:shadow-[inset_0_10px_10px_0_rgb(0_0_0_/_0.1),inset_0_-5px_10px_0_rgb(0_0_0_/_0.1)]
                `}>
                Guarda tutti
            </motion.button>
        </section>
    );
}